<script setup lang="ts">
const { isSuperadmin, isAdmin } = useUserState()
</script>

<template>
  <v-list>
    <v-divider />
    <v-list-item title="Briefings" prepend-icon="$mdiListbox" to="/briefings" />
    <v-divider />
    <v-list-item title="Productos" prepend-icon="$mdiDashboard" to="/products" />
    <v-divider />
    <v-list-item title="Recomendaciones" prepend-icon="$mdiLightbulbOnOutline" to="/insights" />
    <v-divider />
    <v-list-item title="Savvywords" to="/savvywords">
      <template v-slot:prepend>
        <v-icon icon="$mdiSavvywords" class="-rotate-90" />
      </template>
    </v-list-item>
    <v-divider />
    <v-list-item v-if="isSuperadmin || isAdmin" title="Analytics" prepend-icon="$mdiAnalytics" to="/analytics" />
    <v-divider v-if="isSuperadmin || isAdmin" />
  </v-list>
</template>
